import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/Card"
import Section from "../components/Section"
import wallpaper from '../images/wallpaper.jpg'
import animeiju from '../images/animeiju.png'
import recity from '../images/recity.png'
import garits from '../images/garits.png'
import caltrack from '../images/caltrack.png'
import staticdata from '../../staticdata.json'
import contactdata from '../../contactdata.json'
import Cell from "../components/cell"
import styled from 'styled-components'

const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`

const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0px auto 100px auto;
  display: grid;
  padding-left: 90px;
  grid-template-columns: repeat(3, 1fr);
  ${'' /* coloumn-gap: 30px; */}
  row-gap: 20px;
  @media(max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto 100px 10px;
    padding-left: 0;
  }
`


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="Hero">
      <div className="HeroGroup">
        <h1>Hi! <br />I am Jalp Desai</h1>
        <p>Recent computer science graduate from City, University of London</p>
      </div>
    </div>
    <Section 
        image={wallpaper}
        title={"About Me"}
        text={"An enthusiastic programmer who enjoys developing solutions to problems and independently learn new technologies to help resolve these. Able to work cooperatively with others in a team, provide great customer service and determined to produce a working solution for any challenge I undertake."}
        />    
    <SectionCaption id="skills">My Skills</SectionCaption>
    <SectionCellGroup>
        {staticdata.cells.map(cell => (
          <Cell title={cell.title} image={cell.image} />
        ))}
    </SectionCellGroup>
    <div className="Cards" id="projects">
        <h2>Some of the projects I have worked on</h2>
        <div className="CardGroup">
        <Card 
        title="AniMeiju"
        text="iOS tracker anime app"
        link="https://github.com/jalp14/AniMeijuApp"
        image={animeiju}/>

        <Card 
        title="CalTrack"
        text="An iOS app that lets you track your calories"
        link="https://github.com/jalp14/CalTrack"
        image={caltrack}/>

        <Card 
        title="ReCity"
        text="A webapp to view and share recipes"
        link="https://github.com/jalp14/ReCity"
        image={recity}/>

        <Card 
        title="GaritsPOS"
        text="A Point-of-Sale system for a garage(group project)"
        link="https://github.com/jalp14/GARITS"
        image={garits}/>
        </div>
      </div>
      <SectionCaption id="contact">Contact Me</SectionCaption>
        <SectionCellGroup>
            {contactdata.cells.map(cell => (
              <a href={cell.link}>
              <Cell title={cell.title} image={cell.image} />
              </a>
            ))}
        </SectionCellGroup>
  </Layout>
)

export default IndexPage

import React from 'react'
import styled from 'styled-components'



const SectionGroup = styled.div`
    ${'' /* background: url(${props => props.image});
     */}
     background: black;

    height: 350px;
    ${'' /* background-size: cover; */}
    display: grid;
    grid-template-rows: 200px auto;
    grid-gap: 20px; 

    @media (max-width: 720px) {
        height: 720px;
    }

`

// const SectionLogo = styled.img`
//     align-self: end;
//     width: 128px;
//     margin: 0 auto;    
// `

const SectionTitleGroup = styled.div`
    display: grid;
    max-width: 800px; 
    grid-template-columns: 1fr;
    justify-itmes: center;
    justify-content: center;
    align-content: center;
    
    margin: auto;
    gap: 20px;
    grid-template-rows: auto 100%;
    padding: 80px;

    @media (max-width: 720px) {
        grid-template-columns: 1fr;
        padding: auto;
    }
`
const SectionTitle = styled.h3`
    color: white;
    font-size: 50px;
    margin: 0;
    line-height: 1.2;
    ${'' /* padding: 16px; */}

    @media (max-width: 720px) {
        padding: 0px;
    }

`

const SectionText = styled.p`
    color: white;
    line-height: 1.9;
`

const Section = props => (
    <SectionGroup id="about">
        {/* <SectionLogo src={props.logo}/> */}
        <SectionTitleGroup>
            <SectionTitle>{props.title}</SectionTitle>
            <SectionText>{props.text}</SectionText>
        </SectionTitleGroup>
    </SectionGroup>
)

export default Section